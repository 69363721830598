import React from 'react';
import { Tooltip } from '@mui/material';
import { faBook, faShieldHalved, faSection, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICitation } from '../../hooks/ai-search/use-chat';

export interface IChatCitationSummaryProps {
  citations: ICitation[];
}

export interface ICitationSummaryTypeProps {
  icon: IconDefinition;
  title: string;
  citationContentType: number;
}

const citationsTypes: ICitationSummaryTypeProps[] = [
  { icon: faBook, title: 'Schultz Fasit vejledninger:', citationContentType: 0 },
  { icon: faShieldHalved, title: 'Interne arbejdsgange:', citationContentType: 1 },
  { icon: faSection, title: 'Schultz Kommunekoncept:', citationContentType: 2 },
];

function CitationTypeHeader(props: { citations: ICitation[] }) {
  const { citations } = props;
  const [{ CitationSourceType: citationContentType }] = citations;

  const citationSummaryType = citationsTypes?.find(type => type.citationContentType === citationContentType);
  return citationSummaryType ? (
    <>
      <FontAwesomeIcon icon={citationSummaryType?.icon} />
      <span style={{ paddingLeft: '5px' }}>{citationSummaryType?.title}</span>
    </>
  ) : (
    <span />
  );
}

export function ChatCitationSummary(props: IChatCitationSummaryProps) {
  const { citations } = props;

  const orderedCitations = [...citations].sort((a, b) => {
    if (a.CitationSourceType !== undefined && b.CitationSourceType !== undefined) {
      return a.CitationSourceType - b.CitationSourceType;
    }
    return 0;
  });

  // Group citations by type
  const groupedCitations = orderedCitations.reduce<{ [key: number]: ICitation[] }>((groups, citation) => {
    if (!groups[citation.CitationSourceType]) {
      // eslint-disable-next-line no-param-reassign
      groups[citation.CitationSourceType] = [];
    }
    groups[citation.CitationSourceType].push(citation);
    return groups;
  }, {});

  return (
    <details>
      <summary>Vis kilder</summary>
      {Object.entries(groupedCitations).map(([type, citationsGroup]) => (
        <div key={type} style={{ marginTop: '6px', paddingTop: '2px' }}>
          <CitationTypeHeader citations={citationsGroup} />
          {citationsGroup.map((citation, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} style={{ paddingLeft: '20px' }}>
              <Tooltip title={citation.text}>
                <a href={decodeURIComponent(citation.link)} target="_blank" rel="noreferrer">
                  {decodeURIComponent(citation.title).replaceAll('+', ' ')}
                </a>
              </Tooltip>
            </div>
          ))}
        </div>
      ))}
    </details>
  );
}
